import {FC, useContext, useState, SyntheticEvent} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const LoginForm: FC = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const {store} = useContext(Context);
	const handleLogin = (ev: SyntheticEvent) => {
		ev.preventDefault();
		store.login(email, password);
	}

    return (
		<div className='container'>
			<form
				onSubmit={handleLogin}
				method="post"
				action="#"
			>
				<div className='mb-3 mt-3'>
					<label htmlFor="email">Логин</label>
					<input
						id="email"
						name="email"
						className='form-control'
						onChange={e => setEmail(e.target.value)}
						value={email}
						type="email"
						placeholder='Email'
					/>
				</div>
				<div className='mb-4'>
					<label htmlFor="password">Пароль</label>
					<input
						id="password"
						name="password"
						className='form-control'
						onChange={e => setPassword(e.target.value)}
						value={password}
						type="password"
						placeholder='Пароль'
					/>
				</div>
				<div className="d-flex justify-content-between">
					<button
						className='btn btn-primary'
						type="submit"
					>
						Войти
					</button>
					<input
						type="button"
						value="Регистрация"
						className='btn btn-primary'
						onClick={() => store.registration(email, password)}
					/>	
				</div>
			</form>
		</div>
    );
};

export default observer(LoginForm);