import {FC, useContext, useEffect, useState, SyntheticEvent} from 'react';
import LoginForm from "./components/LoginForm";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import {IFile} from "./models/IFile";
import FileService from "./services/FileService";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";

const App: FC = () => {
    const {store} = useContext(Context);
	const [files, setFiles] = useState<IFile[]>([]);
	const [ls, setLS] = useState<string>('');
	const curDate = new Date();
	const [startDate, setStartDate] = useState<Date>(new Date(`${curDate.getFullYear()}-${curDate.getMonth() + 1}`));
    const [endDate, setEndDate] = useState<Date>(new Date(`${curDate.getFullYear()}-${curDate.getMonth() + 1}`));
	registerLocale("ru", ru);
	
    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth()
        }
    }, [store])

	const handleGetFiles = async (ev: SyntheticEvent) => {
		ev.preventDefault();
		await getFiles(ls, startDate, endDate);
	}
	
	async function getFiles(ls: string, from: Date, to: Date) {
		store.setFileListLoading(true);
        try {
			const startPeriod = `${from.getFullYear()}-${from.getMonth()+1}-${from.getDate()}`;
			const endPeriod = `${to.getFullYear()}-${to.getMonth()+1}-${to.getDate()}`;
            const response = await FileService.fetchFiles(ls, startPeriod, endPeriod);
            setFiles(response.data);
			store.setDataLoad();
        } catch (e) {
            console.log(e);
        } finally {
            store.setFileListLoading(false);
        }
    }

    if (store.isLoading) {
        return <div>Загрузка...</div>
    }

    if (!store.isAuth) {
        return (
            <div>
                <LoginForm/>
            </div>
        );
    }
	
	if (!store.user.isActivated) {
		return (
			<>
				<h1>Подтвердите аккаунт!</h1>
				<button onClick={() => store.logout()}>Выйти</button>
			</>
		)
	}
	
    return (
        <div className="container">
			<div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
				Пользователь авторизован {store.user.email}
				<button
					className='btn btn-primary'
					onClick={() => store.logout()}
				>
					Выйти
				</button>
			</div>
			<form 
				onSubmit={handleGetFiles}
				method="post"
				action="#"
			>
				<div className="mb-3">
					<label htmlFor="ls">Лицевой счет</label>
					<input
						id="ls"
						onChange={e => setLS(e.target.value)}
						value={ls}
						type="text"
						placeholder='Введите лицевой счет'
						className='form-control'
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="startDate">Начальная дата</label>
					<DatePicker
						id="startDate"
						className='form-control'
						locale="ru"
						selected={startDate}
						onChange={(date: Date) => {
							setStartDate(date);
							setEndDate(date);
						}}
						selectsStart
						startDate={startDate}
						endDate={endDate}
						dateFormat="MM/yyyy"
						showMonthYearPicker
						disabled={!ls}
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="endDate">Конечная дата</label>
					<DatePicker
						id="endDate"
						className='form-control'
						locale="ru"
						selected={endDate}
						onChange={(date: Date) => setEndDate(date)}
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						dateFormat="MM/yyyy"
						showMonthYearPicker
						disabled={!ls}
					/>
				</div>
				<div className='mb-3'>
					<button
						type="submit"
						className='btn btn-primary'
					>
						{store.isFileListLoading ? 'Загрузка' : 'Получить файлы'}
					</button>
				</div>
			</form>
			{!!files.length &&
				<ul className="files-list">
					{files.map((file, i) =>
						<li key={file.id}>
							<span
								className='a'
								onClick={() => store.download(`/file/${file.filename}`, `${file.ls}_${file.period}.pdf`)}
							>
								{file.period}
							</span>
						</li>
					)}
				</ul>
			}
        </div>
    );
};

export default observer(App);
