import axios from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";
import {AxiosResponse} from 'axios';
import {IFile} from "../models/IFile";
import fileDownload from 'js-file-download';
import {API_URL} from "../http";

export default class FileService {
    static fetchFiles(ls: string, from: string, to: string): Promise<AxiosResponse<IFile[]>> {
		const $api = axios.create({
            withCredentials: true,
            baseURL: API_URL
        })

        $api.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
            config.responseType = 'json';
            return config;
        })

        $api.interceptors.response.use((config) => {
            return config;
        },async (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;
                try {
                    const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true})
                    localStorage.setItem('token', response.data.accessToken);
                    return $api.request(originalRequest);
                } catch (e) {
                    console.log('НЕ АВТОРИЗОВАН')
                }
            }
            throw error;
        })
        return $api.get<IFile[]>(`/files/${ls}?from=${from}&to=${to}`)
    }
	
	static async downloadFile(url: string, filename: string) {
		const $api = axios.create({
            withCredentials: true,
            baseURL: API_URL
        })

        $api.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
            config.responseType = 'blob';
            return config;
        })

        $api.interceptors.response.use((config) => {
            return config;
        },async (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && error.config && !error.config._isRetry) {
                originalRequest._isRetry = true;
                try {
                    const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true})
                    localStorage.setItem('token', response.data.accessToken);
                    return $api.request(originalRequest);
                } catch (e) {
                    console.log('НЕ АВТОРИЗОВАН')
                }
            }
            throw error;
        })
        const res = await $api.get(url);
        fileDownload(res.data, filename);
    }
}